<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <!-- 위험성 평가 목록 -->
      <c-table
        ref="RiskTable"
        title="LBL0002368"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="ramRiskAssessmentPlanId"
        selection="multiple"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addRisk" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && grid.data.length > 0" label="LBLEXCEPT" :showLoading="false" icon="remove" @btnClicked="removeRisk" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-risk',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        iimAccidentId: ''
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        iimAccidentId: '',
        accidentStatusCd: '',
      }),
    },
  },
  data() { 
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sotrable: true
          },
          {
            name: "ramTechniqueName",
            field: 'ramTechniqueName',
            // 평가분류
            label: 'LBL0002369',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            // 평가명
            label: 'LBL0000367',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            // 평가기간
            label: 'LBL0000607',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            // 평가구분
            label: 'LBL0000608',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 등록자
            label: 'LBLREGISTER',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '280px'
      }, 
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      isSave: false,
      linkRow: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isRamTechniqueCd() {
      return Boolean(this.popupParam.ramTechniqueCd)
    },
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    },
    paramInfo() {
        return {
          ramRiskAssessmentPlanId: this.linkRow.ramRiskAssessmentPlanId,
          ramStepCd: this.linkRow.ramStepCd,
          vendorFlag: (this.linkRow.vendorFlag > 0),
        }
      },
      // popupOptionInfo() {
      //   return this.$_.find([
      //     {
      //       ramTechniqueCd: 'RT00000001',
      //       title: 'HAZOP 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/hazop/hazopDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000005',
      //       title: 'K-PSR 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/kpsr/kpsrDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000010',
      //       title: 'CHECKLIST 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/checklist/checkListDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000015',
      //       title: 'JSA 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/jsa/jsaDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000020',
      //       title: 'KRAS 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/kras/krasDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000025',
      //       title: '4M 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/4m/4mDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000030',
      //       title: 'CHARM 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/charm/charmDetail.vue'}`),
      //     },
      //   ], { ramTechniqueCd: this.linkRow.ramTechniqueCd })
      // },
    },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.relate.url;
      // code setting
      if (this.isRamTechniqueCd) {
        this.$set(this.searchParam, 'ramTechniqueCd', this.popupParam.ramTechniqueCd)
        let stepGrpCd = '';
        
        switch (this.popupParam.ramTechniqueCd) {
          case 'RT00000001':
            stepGrpCd = 'RAM_HAZOP_STEP_CD';
            break;
          case 'RT00000005':
            stepGrpCd = 'RAM_KPSR_STEP_CD';
            break;
          case 'RT00000010':
            stepGrpCd = 'RAM_CHECKLIST_STEP_CD';
            break;
          case 'RT00000015':
            stepGrpCd = 'RAM_JSA_STEP_CD';
            break;
          case 'RT00000020':
            stepGrpCd = 'RAM_KRAS_STEP_CD';
            break;
          case 'RT00000025':
            stepGrpCd = 'RAM_4M_STEP_CD';
            break;
          case 'RT00000030':
            stepGrpCd = 'RAM_CHARM_STEP_CD';
            break;
          default:
            break;
        }
        if (stepGrpCd) {
          this.$comm.getStepItems(stepGrpCd).then(_result => {
            _result.splice(1, 1);
            this.stepItems = _result;
          });
        }
      }
      // list setting
    },
    /* eslint-disable no-unused-vars */ 
    addRisk() {
      this.popupOptions.title = 'LBL0002370'; // 위험성 평가 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentRiskPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskPopup;
    },
    closeRiskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            ramStepCd:_item.ramStepCd,
            ramAssessTypeCd:_item.ramAssessTypeCd
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              ramMatrixId: _item.ramMatrixId,
              plantName: _item.plantName,
              ramTechniqueCd: _item.ramTechniqueCd,
              ramTechniqueName: _item.ramTechniqueName,
              assessmentName: _item.assessmentName,
              ramStepCd:_item.ramStepCd,
              ramStepName: _item.ramStepName,
              assessmentDate:_item.assessmentDate,
              ramAssessTypeCd: _item.ramAssessTypeCd,
              ramAssessTypeName:_item.ramAssessTypeName,
              regUserName:_item.regUserName,
              reviewUserName:_item.reviewUserName,
              approvalUserName:_item.approvalUserName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
      }
    },
    removeRisk() {
      let selectData = this.$refs['RiskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        });
      }
    },
    saveAccident() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if(this.popupParam.iimAccidentId) {
            this.$set(this.save, 'iimAccidentId',  this.tabParam.iimAccidentId);
            this.$set(this.save, 'plantCd',  this.tabParam.plantCd);
            this.$set(this.save, 'accidentStatusCd',  this.tabParam.accidentStatusCd);
          }
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    linkClick(row) {
      // this.$set(this.$data, 'linkRow', row)
      // this.$_.extend(this.popupOptions, this.popupOptionInfo)
      this.popupOptions.title = 'LBL0002371'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row ? row.ramRiskAssessmentPlanId : '',
        ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        vendorFlag: row ? (row.vendorFlag > 0) : false,
        ramTechniqueCd: row.ramTechniqueCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
